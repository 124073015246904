import { useEffect } from 'react'

import { useReactiveVar } from '@apollo/client'
import setLanguage from 'next-translate/setLanguage'
import { useRouter } from 'next/router'
import { useSnackbar } from 'notistack'

import { namedOperations, useGetLocaleQuery, useUpdateLocaleMutation } from '~/graphql/generated/schema'
import { LOCKED_LOCALE_VAR_NAME, lockedLocaleVar } from '~/graphql/reactive-vars'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

export function useLockedLocaleVar() {
  const lockedLocale = useReactiveVar(lockedLocaleVar)

  useEffect(() => {
    const parsed = JSON.parse(localStorage.getItem(LOCKED_LOCALE_VAR_NAME) ?? 'null')
    parsed && lockedLocaleVar(parsed)
  })

  return lockedLocale
}

export function useLockedLocale() {
  const { data, loading } = useGetLocaleQuery()
  const [updateLocale] = useUpdateLocaleMutation({
    refetchQueries: [namedOperations.Query.GetLocale],
  })
  const lockedLocale = useLockedLocaleVar()
  const { locale } = useRouter()
  const { enqueueSnackbar } = useSnackbar()
  const t = useTranslationForNamespace('common')
  const DID_FORCE_LOCALE_CHANGE = 'didForceLocaleChange'

  useEffect(() => {
    if (loading) {
      return
    }
    if (data?.me?.locale && data.me.locale !== lockedLocale) {
      lockedLocaleVar(data.me.locale)
      localStorage.setItem(DID_FORCE_LOCALE_CHANGE, 'true')
      setLanguage(data.me.locale)
    } else if (locale) {
      lockedLocaleVar(locale)
      if (data?.me?.locale !== lockedLocale) {
        updateLocale({
          variables: {
            input: {
              locale,
            },
          },
        })
      }
    }
  }, [loading, data, lockedLocale, locale, updateLocale])

  useEffect(() => {
    // This effect happens after navigation so that the snackbar is enqueued with the correct language
    if (localStorage.getItem(DID_FORCE_LOCALE_CHANGE)) {
      enqueueSnackbar(t('notifications.lockedLocaleOverrode'), {
        preventDuplicate: true,
      })
      localStorage.removeItem(DID_FORCE_LOCALE_CHANGE)
    }
  }, [enqueueSnackbar, t])
}
